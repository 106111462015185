var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "section",
        {
          staticClass:
            "l-grid l-grid--2 l-grid--1-sp l-grid--gap-x_4l l-grid--gap-y_2l"
        },
        _vm._l(_vm.items, function(item) {
          return _c("div", { key: item.id }, [
            _c("div", {
              domProps: { innerHTML: _vm._s(item.content.rendered) }
            }),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(item.title.rendered))])
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c("page-navi", {
        attrs: {
          "current-page": _vm.currentPage,
          "total-pages": _vm.totalPages
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }