/**
 * キャラの getter
 */

export default {
  /**
   * スケジュール一覧
   */
  schedules: state => state.scheduleData,

  /**
   * 加工したスケジュール一覧
   */
  convertedSchedules: state => {
    return state.scheduleData.map(convertSchedule);
  },

  // /**
  //  * 指定されたグループのキャラを取得
  //  */
  // groupCharas: state => payload => state.charaData.charas.filter(chara => chara.group === payload.groupId),

  // /**
  //  * 全キャラを取得
  //  */
  // charas: state => state.charaData.charas,

  // /**
  //  * 指定されたキャラを取得
  //  */
  // chara: state => payload => state.charaData.charas.find(chara => chara.id === payload.charaId),

  // /**
  //  * 現在選択されているキャラのデータ
  //  * 何も選択されてなければ空のオブジェクトを返す
  //  */
  // nowChara: (state, getters) => {
  //   if (state.nowCharaId) {
  //     return getters.chara({ charaId: state.nowCharaId });
  //   }
  //   return {};
  // },

  // /**
  //  * 指定されたキャラIDの位置を取得
  //  */
  // charaIndex: state => payload =>
  //   // getters.personals() で現在のカテゴリでフィルタリングしたリストが取得できる
  //   state.charaData.charas.findIndex(chara => chara.id === payload.charaId),

  // /**
  //  * 次のキャラIDを取得
  //  */
  // nextCharaId: (state, getters) => {
  //   const length = state.charaData.charas.length;
  //   const now_index = getters.charaIndex({ charaId: state.nowCharaId });
  //   const index = (now_index + 1) % length;
  //   return state.charaData.charas[index].id;
  // },
  // /**
  //  * 前のキャラIDを取得
  //  */
  // prevCharaId: (state, getters) => {
  //   const length = state.charaData.charas.length;
  //   const now_index = getters.charaIndex({ charaId: state.nowCharaId });
  //   const index = now_index - 1 >= 0 ? now_index - 1 : length - 1;
  //   return state.charaData.charas[index].id;
  // },
};

/**
 * スケジュールを画面表示用にコンバート
 */
function convertSchedule(schedule) {
  // 開催日を分解
  const converted = schedule;
  const event_date = converted.acf.event_date.split('/');
  converted.year = event_date[0];
  converted.month = event_date[1];
  converted.day = event_date[2];
  const date = new Date(converted.acf.event_date);
  converted.week = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'][date.getDay()];
  return converted;
}
