var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("nav", { staticClass: "c-pagenavi" }, [
    _c(
      "ul",
      { staticClass: "page-numbers" },
      [
        _vm.totalPages >= 2 && _vm.currentPage >= 2
          ? _c("page-navi-button", {
              attrs: {
                type: "PREV",
                "page-number": _vm.currentPage - 1,
                "page-text": _vm.prevText
              }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm._l(_vm.numbers, function(item, index) {
          return _c("page-navi-button", {
            key: index,
            attrs: {
              type: item.type,
              "page-number": item.pageNumber,
              "page-text": item.pageText
            }
          })
        }),
        _vm._v(" "),
        _vm.totalPages >= 2 && _vm.currentPage < _vm.totalPages
          ? _c("page-navi-button", {
              attrs: {
                type: "NEXT",
                "page-number": _vm.currentPage + 1,
                "page-text": _vm.nextText
              }
            })
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }