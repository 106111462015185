/**
 * ページ内スクロール
 * https://www.npmjs.com/package/smooth-scroll
 * <a href="#target" class="js-pagescroll">
 *
 * スクロールを発生させることも可能
 * scroll.animateScroll(
 *   target_y,
 *   '',
 *   {
 *       speed: 1500,
 *       easing: 'easeInOutCubic'
 *   }
 * );
 *
 * キャンセルも可能
 * scroll.cancelScroll();
 *
 */

/* eslint no-unused-vars:off */

import SmoothScroll from 'smooth-scroll';

export default function() {
  // 汎用スクロール
  const click_scroll = new SmoothScroll('a.js-pagescroll', {
    speed: 500,
    offset: 0,
    easing: 'easeInOutCubic',
  });

  const scroll = new SmoothScroll();

  // ページトップにスクロール
  $('.js-return-up').on('click', ev => {
    scroll.animateScroll(0, {
      speed: 500,
      offset: 0,
      easing: 'easeInOutCubic',
    });
    ev.preventDefault();
  });
}
