/**
 * スケジュール表示
 */
/* eslint no-new:off */

import Vue from 'vue';
import Vuex from 'vuex';
import VueLazyload from 'vue-lazyload';
import VueInview from '@sygnas/vue-inview';
import homeScheduleList from './schedule/component/home-schedule-list.vue';
import store_setting from '../../store/schedule/index';
import * as mutations from '../../store/schedule/mutation-types';

export default function() {
  // データを読み込む
  $.ajax('/news/top-schedule/', {
    cache: false,
    dataType: 'json',
  }).done(json => {
    initVue(json);
  });
}

/**
 *
 */
function initVue(json) {
  // 遅延読み込み
  Vue.use(VueLazyload);

  // inview
  Vue.use(VueInview);

  // ストア設定
  Vue.use(Vuex);
  const store = new Vuex.Store(store_setting);
  store.commit(mutations.SET_SCHEDULE_DATA, { json });

  new Vue({
    store,
    el: '#app-schedule',
    components: {
      homeScheduleList,
    },
    template: '<home-schedule-list />',
  });
}
