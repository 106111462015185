/**
 * スケジュール
 */
/* eslint no-new:off */

import Vue from 'vue';
import Vuex from 'vuex';
import VueRouter from 'vue-router';
import VueLazyload from 'vue-lazyload';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
import VueInview from '@sygnas/vue-inview';
import VueScrollTo from 'vue-scrollto';
import route_setting from './route';
import store_setting from '../../store/schedule/index';
// import * as mutations from '../../store/schedule/mutation-types';
import scheduleContent from './component/schedule-content.vue';

export default function() {
  // トップページ以外は抜ける
  if ($('#page-schedule').length <= 0) return;

  initVue();
}

/**
 *
 */
function initVue() {
  // 遅延読み込み
  Vue.use(VueLazyload);
  // inview
  Vue.use(VueInview);
  // スワイプ
  Vue.use(VueAwesomeSwiper);
  // ルーター設定
  Vue.use(VueRouter);
  const router = new VueRouter(route_setting);
  // ページ内スクロール
  Vue.use(VueScrollTo, {
    offset: -100,
  });

  // ストア設定
  Vue.use(Vuex);
  const store = new Vuex.Store(store_setting);
  // store.commit(mutations.SET_SCHEDULE_DATA, { json });

  new Vue({
    store,
    router,
    el: '#app-schedule',
    components: {
      scheduleContent,
    },
    // template: '<schedule-content />',
  });
}
