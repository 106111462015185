/**
 * Youtube一覧
 */

/* eslint no-new:off */

import Vue from 'vue';
import VueRouter from 'vue-router';
import youtubeContent from './components/youtubeContent.vue';

export default function() {
  if ($('#app-youtube').length <= 0) return;

  // ルーター設定
  Vue.use(VueRouter);
  const router = new VueRouter({
    routes: [
      {
        name: 'page',
        path: '/:pageNumber',
      },
    ],
  });

  new Vue({
    el: '#app-youtube',
    router,
    components: {
      youtubeContent,
    },
  });
}
