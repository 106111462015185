<template>
  <li>
    <router-link
      v-if="type === 'PREV'"
      class="page-numbers prev"
      :to="{ name: 'page', params: { pageNumber } }"
      v-html="pageText"
    ></router-link>

    <router-link
      v-else-if="type === 'NEXT'"
      class="page-numbers next"
      :to="{ name: 'page', params: { pageNumber } }"
      v-html="pageText"
    ></router-link>

    <span v-else-if="type === 'CURRENT'" class="page-numbers">{{ pageNumber }}</span>

    <router-link
      v-else-if="type === 'NUMBER'"
      class="page-numbers"
      :to="{ name: 'page', params: { pageNumber } }"
      v-html="pageNumber"
    >
    </router-link>

    <span v-else class="page-numbers dots" v-html="pageText"></span>
  </li>
</template>

<script>
export default {
  props: {
    // ページ番号：NUMBER
    // 次：NEXT
    // 前：PREV
    // 現在のページ：CURRENT
    type: {
      type: String,
      default() {
        return 'NUMBER';
      },
    },
    pageNumber: {
      type: Number,
      default() {
        return 0;
      },
    },
    pageText: {
      type: String,
      default() {
        return '';
      },
    },
  },
};
</script>
