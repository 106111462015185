/**
 * キャラの mutation
 */

/* eslint no-param-reassign:off */

import * as mutation_types from './mutation-types';

export default {
  /**
   * 読み込んだjsonを設定する
   */
  [mutation_types.SET_SCHEDULE_DATA](state, payload) {
    state.scheduleData = payload.json;
  },
  // /**
  //  * 現在表示しているキャラのIDを設定
  //  */
  // [mutation_types.SET_CHARA_ID](state, payload) {
  //   state.nowCharaId = payload.charaId;
  // },
  // /**
  //  * 次のキャラか、前のキャラか
  //  */
  // [mutation_types.SET_IS_NEXT](state, payload) {
  //   state.isNext = payload.isNext;
  // },
};
