<template>
  <section class="home-schedule-list l-grid l-grid--3 l-grid--1-sp l-grid--gap-x_3l">
    <home-schedule-item v-for="(schedule, index) in schedules" :key="`schedule-${index}`" :schedule="schedule" />
  </section>
</template>

<script>
import homeScheduleItem from './home-schedule-item.vue';

export default {
  components: {
    homeScheduleItem,
  },
  computed: {
    schedules() {
      return this.$store.getters.convertedSchedules;
    },
  },
};
</script>
