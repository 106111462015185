/**
 * ソーシャルシェアボタン
 * https://github.com/sygnas/syg-jp-social-share
 */

import social_share from '@sygnas/jp-social-share';

export default function() {
  social_share('.js-share-button');
}
