<template>
  <div v-inview class="schedule-header e-iv e-iv--t">
    <div class="schedule-header__heading">
      <h2 v-if="!hideHeading" class="p-h_underleft p-h_underleft--section">{{ year }}.{{ month }}</h2>
    </div>

    <router-link
      :to="{ name: 'month', params: { year: prevYear, month: prevMonth } }"
      class="schedule-header__btn p-btn p-btn--primary p-btn--prev"
    >
      <span class="p-btn__inner">{{ prevYear }}.{{ prevMonth }}</span>
    </router-link>

    <router-link
      :to="{ name: 'month', params: { year: nextYear, month: nextMonth } }"
      class="schedule-header__btn p-btn p-btn--primary p-btn--next"
    >
      <span class="p-btn__inner">{{ nextYear }}.{{ nextMonth }}</span>
    </router-link>
  </div>
</template>

<script>
import dayjs from 'dayjs';

export default {
  props: {
    year: {
      type: String,
      default() {
        return '2019';
      },
    },
    month: {
      type: String,
      default() {
        return '1';
      },
    },
    hideHeading: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  computed: {
    nextYearMonth() {
      return dayjs()
        .set('year', this.year)
        .set('month', this.month);
    },
    nextYear() {
      return this.nextYearMonth.format('YYYY');
    },
    nextMonth() {
      return this.nextYearMonth.format('MM');
    },
    prevYearMonth() {
      return dayjs()
        .set('year', this.year)
        .set('month', this.month)
        .add(-2, 'month');
    },
    prevYear() {
      return this.prevYearMonth.format('YYYY');
    },
    prevMonth() {
      return this.prevYearMonth.format('MM');
    },
  },
};
</script>
