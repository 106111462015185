/**
 * 最初のビデオを再生
 */
/* eslint no-new:off */

import Vue from 'vue';
import Cookies from 'js-cookie';
import homeVideo from './video/home-video.vue';

export default function() {
  // スマホだったら再生しない
  if (document.body.getAttribute('data-is-mobile') === 'true') {
    document.querySelector('body').dataset.scrollLock = 'false';
    // ビデオ再生完了通知
    $(window).trigger('home_video_finished');
    return;
  }

  // Cookieに記録があったら再生しない
  // console.log('cookie', Cookies.get('topVideo'));
  if (Cookies.get('topVideo') === 'true') {
    document.querySelector('body').dataset.scrollLock = 'false';
    // ビデオ再生完了通知
    $(window).trigger('home_video_finished');
    return;
  }

  new Vue({
    el: '#app-video',
    components: {
      homeVideo,
    },
    template: '<home-video />',
  });
}
