/**
 * ユーザーエージェント確認
 * OSを見てスマホ系だと判定したら <body data-is-mobile="true"> を設定する
 * PCだと判定したら <body data-is-mobile="false"> を設定
 */

import platform from 'platform';

export default function() {
  const mobile = ['iOS', 'Android'];
  const os = platform.os.family;

  if (mobile.indexOf(os) === -1) {
    document.body.setAttribute('data-is-mobile', 'false');
  } else {
    document.body.setAttribute('data-is-mobile', 'true');
  }
}
