/**
 * ページ読み込みプログレスバー
 */

/* eslint no-unused-vars:off */

import preloader from 'preloader';

export default function(add_urls = []) {
  // プログレスバーエレメント
  const $progress_bar = $('.js-fadein-progress');
  // プリローダー
  const loader = preloader({ xhrImages: false });

  // urlリスト作成
  const image_urls = get_image_list();
  const urls = Object.assign(image_urls, add_urls);

  // Promiseを返す
  return new Promise((resolve, reject) => {
    // プリローダーに登録
    urls.forEach(url => {
      loader.add(url);
    });

    // 読み込み中：バーエレメントの幅調整
    loader.on('progress', (percent, url) => {
      $progress_bar.css({ width: `${percent * 100}%` });
    });

    // 読み込み完了
    // 読み込み完了通知、プログレスエレメントを幅100%にする
    loader.on('complete', () => {
      $progress_bar.css({ width: '100%' });
      $(window).trigger('page_load_complete');
      resolve();
    });

    // 読み込み開始
    loader.load();
  });
}

/**
 * <img> をチェックする
 * @return urls {Array} URLの配列
 */
function get_image_list() {
  const hostname = /^https?:\/\/[^/]+\//.exec(window.location.href)[0];
  const images = document.getElementsByTagName('img');
  const urls = [];

  // <img>タグを全てチェック
  for (let i = 0; i < images.length; i += 1) {
    const src = images[i].src;
    // 違うホストのものは弾く
    if (src.indexOf(hostname) === 0) {
      urls.push(src);
    }
  }
  return urls;
}
