<!--

ざっくり5万

テンプレート変更 3万
新規項目追加 2万

-->

<template>
  <article :id="`event-${schedule.post_id}`" v-inview class="schedule-item e-iv e-iv--t">
    <div class="schedule-date" :data-is-single="isSingle">
      <div class="schedule-date__inner">
        <span v-if="isSingle" class="schedule-date__year">{{ schedule.year }}</span>

        <span v-if="isSingle" class="schedule-date__day" :data-is-single="isSingle">
          {{ schedule.month }}.{{ schedule.day }}
        </span>
        <span v-else class="schedule-date__day">{{ schedule.day }}</span>

        <span class="schedule-date__week">{{ schedule.week }}</span>
      </div>
      <!-- /.schedule-date__inner -->
    </div>

    <div class="schedule-item__visuals">
      <swiper ref="visualSwiper" :options="swiperOption">
        <swiper-slide v-for="(image, swpieIndex) in schedule.acf.visuals" :key="`slide-${swpieIndex}`">
          <span :style="{ backgroundImage: `url(${image.image.url})` }" class="schedule-item__thumb"></span>
        </swiper-slide>
      </swiper>
      <div
        v-if="hasMultiVisuals"
        :class="`schedule-item__pagination--${index}`"
        class="schedule-item__pagination"
      ></div>
    </div>

    <!-- <span v-lazy:background-image="schedule.thumbnail" class="schedule-item__thumb"></span> -->

    <div class="schedule-item__data">
      <header class="schedule-item__header">
        <div class="schedule-types">
          <span v-for="type in schedule.acf.event_type" :key="`event-type-${type}`" class="schedule-types__item">{{
            type
          }}</span>
        </div>

        <!-- リスト表示の時はリンクを付ける -->
        <a v-if="!isSingle" :href="schedule.permalink" class="schedule-item__title-link">
          <h3 class="schedule-item__artist">{{ schedule.acf.artist }}</h3>
          <p class="schedule-item__title" v-html="schedule.post_title"></p>
        </a>
        <template v-else>
          <h3 class="schedule-item__artist test">{{ schedule.acf.artist }}</h3>
          <p class="schedule-item__title" v-html="schedule.post_title"></p>
        </template>
      </header>

      <template v-if="schedule.acf.open_start">
        <h4 class="schedule-item__badge p-badge p-badge--primary">OPEN/START</h4>
        <p class="schedule-item__open-start">{{ schedule.acf.open_start }}</p>
      </template>

      <template v-if="isSingle && schedule.acf.adv">
        <h4 class="schedule-item__badge p-badge p-badge--primary">CHARGE</h4>
        <div class="schedule-item__adv" v-html="schedule.acf.adv"></div>
      </template>

      <template v-if="isSingle && schedule.acf.info">
        <h4 class="schedule-item__badge p-badge p-badge--primary">INFORMATION</h4>
        <div class="schedule-item__info" v-html="schedule.acf.info"></div>
      </template>
    </div>
  </article>
</template>

<script>
import { prop_schedule } from '../../../store/schedule/default-scheduleData';

export default {
  props: {
    index: {
      type: Number,
      default() {
        return 0;
      },
    },
    schedule: {
      type: Object,
      default: () => {
        return prop_schedule;
      },
    },
    isSingle: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  computed: {
    /**
     * VueAwesomeSwiper の設定
     */
    swiperOption() {
      return {
        pagination: {
          el: `.schedule-item__pagination--${this.index}`,
          clickable: true,
        },
        loop: this.hasMultiVisuals,
        autoplay: this.hasMultiVisuals ? { delay: 3000 } : false,
      };
    },
    /**
     * 複数画像あるか。ある：true / ない：false
     */
    hasMultiVisuals() {
      return this.schedule.acf.visuals && this.schedule.acf.visuals.length >= 2;
    },
  },
  mounted() {
    console.log('item', this.schedule);
  },
};
</script>
