<template>
  <a
    v-inview
    :href-org="schedule.acf.link"
    :href-list="`/schedule/#/${schedule.year}/${schedule.month}/event-${schedule.post_id}`"
    :href="schedule.permalink"
    class="home-schedule-item home-schedule-item__link e-iv e-iv--t"
    target-org="_blank"
  >
    <span v-lazy:background-image="schedule.acf.visuals[0].image.url" class="home-schedule-item__thumb"></span>

    <div class="home-schedule-item__data">
      <div class="home-schedule-item__event-date">
        <span class="home-schedule-item__month">{{ schedule.month }}</span>
        <span class="home-schedule-item__day">{{ schedule.day }}</span>
        <span class="home-schedule-item__week">{{ schedule.week }}</span>
      </div>

      <h3 class="home-schedule-item__artist">{{ schedule.acf.artist }}</h3>

      <p class="home-schedule-item__title" v-html="schedule.post_title"></p>
    </div>
  </a>
</template>

<script>
import { prop_schedule } from '../../../../store/schedule/default-scheduleData';

export default {
  props: {
    schedule: {
      type: Object,
      default: () => {
        return prop_schedule;
      },
    },
  },
  mounted() {
    console.log(this.schedule);
  },
};
</script>
