/**
 * キャラデータのストア
 * キャラデータの管理と、取得。
 */

import state from './state';
import mutations from './mutations';
import getters from './getters';

export default {
  state,
  mutations,
  getters,
};
