/**
 * fancybox3 を使ったポップアップ
 * http://uzurea.net/explanation-of-fancybox3/
 */

import lazyload from './common_lazyload';
// import * as scrollLock from './common_scroll-lock';

export const NORMAL_SETTINGS = {
  infobar: false,
  toolbar: true,
  buttons: ['close'],
  btnTpl: {
    close:
      '<button data-fancybox-close class="fancybox-button fancybox-button--close" title="{{CLOSE}}"><i class="i-arrows-times-1"></i></button>',
  },
};

export const AJAX_SETTINGS = {
  type: 'ajax',
  infobar: false,
  toolbar: true,
  buttons: ['close'],
  btnTpl: {
    close:
      '<button data-fancybox-close class="fancybox-button fancybox-button--close" title="{{CLOSE}}"><i class="i-arrows-times-1"></i></button>',
  },
  smallBtn: false,
  // beforeShow: (instance, current) => {
  //   window.location.hash = current.src;
  // },
  afterShow: () => {
    // scrollLock.scrollLock();
    lazyload();
  },
  // beforeClose: () => {
  //   window.location.hash = '';
  //   // scrollLock.scrollReturn();
  // },
};

export default function() {
  $('[data-fancybox]').fancybox(NORMAL_SETTINGS);

  $('[data-fancybox-ajax]').fancybox(AJAX_SETTINGS);
}
