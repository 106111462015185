<template>
  <section class="schedule-content">
    <schedule-header v-if="!isSingle" :year="currentYear" :month="currentMonth" />

    <div class="schedule-content__content">
      <div v-if="!isLoading && schedules.length" class="schedule-list">
        <schedule-item
          v-for="(schedule, index) in schedules"
          :key="`schedule-${index}`"
          :index="index"
          :schedule="schedule"
          :is-single="isSingle"
        />
      </div>
      <p v-else-if="!isLoading" v-inview class="e-iv e-iv--t">この月の情報はありません。</p>
    </div>
    <!-- /.schedule-content__content -->

    <schedule-header v-if="!isSingle" :hide-heading="true" :year="currentYear" :month="currentMonth" />
  </section>
</template>

<script>
import dayjs from 'dayjs';
import * as mutations from '../../../store/schedule/mutation-types';
import scheduleItem from './schedule-item.vue';
import scheduleHeader from './schedule-header.vue';

export default {
  components: {
    scheduleItem,
    scheduleHeader,
  },
  props: {
    // wordpressのプレビューか
    isSingle: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      // 現在表示している年月
      currentYear: '',
      currentMonth: '',
      // 読み込み中か
      isLoading: true,
    };
  },
  computed: {
    schedules() {
      return this.$store.getters.convertedSchedules;
    },
  },
  watch: {
    $route: 'changeRoute',
  },
  mounted() {
    this.getSchedules();
  },
  methods: {
    /**
     * スケジュールを取得
     */
    getSchedules() {
      // シングル表示（or プレビュー）なら埋め込みjsonを取得。
      // そうでないなら REST API から指定された月の情報を取得。指定が無ければ今月の情報を取得
      if (this.isSingle) {
        this.getInlineSchedules();
      } else {
        this.getAjaxSchedules();
      }
    },
    /**
     * プレビュー用。スケジュールをページ内に記載されたjsonで取得する
     */
    getInlineSchedules() {
      const json = document.getElementById('json-data');
      const data = JSON.parse(json.textContent);
      // console.log('preview', data);
      this.$store.commit(mutations.SET_SCHEDULE_DATA, { json: data });
      // console.log('preview2', this.$store.state);

      setTimeout(() => {
        this.isLoading = false;
      }, 200);
    },
    /**
     * 指定月のスケジュールをwordpressの REST API から取得
     * VueRouterのparamsから取得し、なければパラメーター無し（今月を表す）で取得。
     */
    getAjaxSchedules() {
      this.isLoading = true;
      const monthParam = this.sanitizeMonth(this.$route.params.year, this.$route.params.month);
      const url = `/news/monthly-schedule/${monthParam}`;

      // データを読み込む
      $.ajax(url, {
        cache: false,
        dataType: 'json',
      })
        .done(json => {
          this.$store.commit(mutations.SET_SCHEDULE_DATA, { json });
          // // 特定のイベントIDが指定されていたらスクロール
          this.pageScroll();
        })
        .fail(() => {
          this.$store.commit(mutations.SET_SCHEDULE_DATA, { json: [] });
        })
        .always(() => {
          setTimeout(() => {
            this.isLoading = false;
          }, 200);
        });
    },
    /**
     * 与えられた文字列が「20xxxx」の数値になっているか確認して、
     * 問題無ければその数字を返す。問題あれば今月を返す。
     * その結果を currentYear currentMonth に記録
     */
    sanitizeMonth(year, month) {
      const yearMonth = `${year}${month}`;
      if (yearMonth.match(/^20\d{4}$/)) {
        this.currentYear = year;
        this.currentMonth = month;
        return `?month=${yearMonth}`;
      }
      this.currentYear = dayjs().format('YYYY');
      this.currentMonth = dayjs().format('MM');
      return `?month=${this.currentYear}${this.currentMonth}`;
    },
    /**
     * 少し待ってからページ内スクロール
     */
    pageScroll() {
      const id = this.$route.params.id;
      if (!id) return;

      setTimeout(() => {
        this.$scrollTo(`#${id}`);
      }, 300);
    },
    /**
     * ルートが変更された
     */
    changeRoute() {
      this.getSchedules();
    },
  },
};
</script>
