<template>
  <nav class="c-pagenavi">
    <ul class="page-numbers">
      <page-navi-button
        v-if="totalPages >= 2 && currentPage >= 2"
        :type="'PREV'"
        :page-number="currentPage - 1"
        :page-text="prevText"
      />
      <page-navi-button
        v-for="(item, index) in numbers"
        :key="index"
        :type="item.type"
        :page-number="item.pageNumber"
        :page-text="item.pageText"
      />
      <page-navi-button
        v-if="totalPages >= 2 && currentPage < totalPages"
        :type="'NEXT'"
        :page-number="currentPage + 1"
        :page-text="nextText"
      />
    </ul>
  </nav>
</template>

<script>
import pageNaviButton from './pageNaviButton.vue';

export default {
  components: {
    pageNaviButton,
  },
  props: {
    showMaxButtons: {
      type: Number,
      default() {
        return 2;
      },
    },
    totalPages: {
      type: Number,
      default() {
        return 1;
      },
    },
    currentPage: {
      type: Number,
      default() {
        return 1;
      },
    },
    apostrophe: {
      type: String,
      default() {
        return '…';
      },
    },
    nextText: {
      type: String,
      default() {
        return '<i class="i-arrows-angle-2-r"></i>';
      },
    },
    prevText: {
      type: String,
      default() {
        return '<i class="i-arrows-angle-2-l"></i>';
      },
    },
  },
  computed: {
    numbers() {
      const output = [];
      const currentPage = this.currentPage;
      const totalPages = this.totalPages;
      const showMaxButtons = this.showMaxButtons;

      // console.log('numbers', currentPage, totalPages);

      [...Array(totalPages)].map((_, i) => {
        // ページ番号は 1 から開始するので +1 する
        const number = i + 1;
        let hasBeforeApostorophe = false;
        let hasAfterApostorophe = false;

        if (number === currentPage) {
          // 現在のページ
          output.push({ type: 'CURRENT', pageNumber: number });
        } else if (number === 1 || number === totalPages) {
          // 最初・最後のページ
          output.push({ type: 'NUMBER', pageNumber: number });
        } else if (currentPage - showMaxButtons <= number && number <= currentPage + showMaxButtons) {
          // 現在のページの前後
          output.push({ type: 'NUMBER', pageNumber: number });
        } else if (!hasBeforeApostorophe && number <= currentPage - 2) {
          // 前方の省略記号
          hasBeforeApostorophe = true;
          output.push({ type: 'APOSTOROPHE', pageText: this.apostrophe });
        } else if (!hasAfterApostorophe && currentPage + 2 <= number) {
          // 後方の省略記号
          hasAfterApostorophe = true;
          output.push({ type: 'APOSTOROPHE', pageText: this.apostrophe });
        }
        return [];
      });
      return output;
    },
  },
  // watch: {
  //   this.props: 'changeRoute',
  // },
  mounted() {
    // console.log('mounted');
    // this.changeRoute();
  },
  methods: {
    // ルートが変わった
    // changeRoute() {
    //   if (this.$route.params.pageNumber) {
    //     // console.log('change route', this.$route.params);
    //     this.currentPage = Number(this.$route.params.pageNumber);
    //   }
    // },
  },
};
</script>
