/**
 * スクロール in View
 * 画面に入ると data-inview 属性が「true」になるので、
 * css 側でエフェクトを制御する
 */

import InView from '@sygnas/scroll-inview';

export default function() {
  const inview = new InView('.js-inview');
  inview.start();
}
