/**
 * ルート設定
 */

// import charaListCompo from './component/chara-list.vue';
// import charaDetailCompo from './component/chara-detail.vue';

export default {
  routes: [
    {
      path: '/',
      // component: charaListCompo,
    },
    {
      name: 'month',
      path: '/:year/:month',
      // component: charaDetailCompo,
    },
    {
      name: 'monthId',
      path: '/:year/:month/:id',
      // component: charaDetailCompo,
    },
  ],
};
