/**
 * スケジュールのデフォルト
 */

export const prop_schedule = {
  post_id: 0,
  post_name: 'slug',
  post_title: 'イベント名',
  thumbnail: 'clear.gif',
  permalink: '',
  acf: {
    event_date: '2019/11/16',
    open_start: '開場 / 開演',
    artist: 'アーティスト名',
    link: 'http://google.com/',
    adv: '入場料',
    info: 'その他情報',
  },
};

export default {
  schedules: [prop_schedule],
};
