/**
 * ページ読み込みが終わった後の処理
 */

// スクロール検知表示
import inview from './common_scroll-invew';

export default function() {
  setTimeout(fadein, 200);
  setTimeout(remove_fadein, 1200);
  setTimeout(inview, 1200);
}

/**
 * フェードインさせる
 */
function fadein() {
  $('.js-fadein').attr('data-progress-finish', 'true');
  // フェードインイベントの通知
  // console.log('page_fadein');
  $(window).trigger('page_fadein');
}

/**
 * フェードインエレメントを削除
 */
function remove_fadein() {
  $('.js-fadein').remove();
}
