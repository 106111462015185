import { AJAX_SETTINGS, NORMAL_SETTINGS } from '../common/common_popup';

export default function() {
  const src = $('body').data('popup-src');
  const type = $('body').data('popup-type');
  let is_showed = false;

  $(window).on('home_video_finished', () => {
    if (is_showed) return;
    is_showed = true;
    showFancyBox();
  });

  function showFancyBox() {
    if (src) {
      if (type === 'youtube') {
        $.fancybox.open({
          src,
          ...NORMAL_SETTINGS,
        });
      } else {
        $.fancybox.open({
          src,
          ...AJAX_SETTINGS,
        });
      }
    }
  }
}
