var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    [
      _vm.type === "PREV"
        ? _c("router-link", {
            staticClass: "page-numbers prev",
            attrs: {
              to: { name: "page", params: { pageNumber: _vm.pageNumber } }
            },
            domProps: { innerHTML: _vm._s(_vm.pageText) }
          })
        : _vm.type === "NEXT"
        ? _c("router-link", {
            staticClass: "page-numbers next",
            attrs: {
              to: { name: "page", params: { pageNumber: _vm.pageNumber } }
            },
            domProps: { innerHTML: _vm._s(_vm.pageText) }
          })
        : _vm.type === "CURRENT"
        ? _c("span", { staticClass: "page-numbers" }, [
            _vm._v(_vm._s(_vm.pageNumber))
          ])
        : _vm.type === "NUMBER"
        ? _c("router-link", {
            staticClass: "page-numbers",
            attrs: {
              to: { name: "page", params: { pageNumber: _vm.pageNumber } }
            },
            domProps: { innerHTML: _vm._s(_vm.pageNumber) }
          })
        : _c("span", {
            staticClass: "page-numbers dots",
            domProps: { innerHTML: _vm._s(_vm.pageText) }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }