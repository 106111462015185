var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "home-video-wrapper" },
    [
      _c("transition", { attrs: { name: "e-v-transition_fade" } }, [
        !_vm.isEnded
          ? _c(
              "div",
              {
                staticClass: "home-video",
                attrs: { "data-loaded": _vm.isLoaded ? "true" : "false" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.finishVideo($event)
                  }
                }
              },
              [
                _c("video", {
                  staticClass: "home-video__video",
                  attrs: { src: _vm.videoUrl, muted: "", playsinline: "" },
                  domProps: { muted: true },
                  on: { canplay: _vm.canPlay, ended: _vm.finishVideo }
                }),
                _vm._v(" "),
                !_vm.isLoaded
                  ? _c("span", { staticClass: "p-loading home-video__loading" })
                  : _vm._e()
              ]
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }