/**
 * トップページ
 */

// wordpress 更新情報
import wordpress from './home_wordpress';
import schedule from './home_schedule';
import video from './home_video';
import popup from './home_popup';

export default function() {
  // トップページ以外は抜ける
  if ($('#page-home').length <= 0) return;

  wordpress();
  schedule();
  popup();
  video();
}
