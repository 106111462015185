<template>
  <div>
    <section class="l-grid l-grid--2 l-grid--1-sp l-grid--gap-x_4l l-grid--gap-y_2l">
      <div v-for="item in items" :key="item.id">
        <div v-html="item.content.rendered" />
        <p>{{ item.title.rendered }}</p>
      </div>
    </section>

    <page-navi :current-page="currentPage" :total-pages="totalPages"></page-navi>
  </div>
</template>

<script>
import pageNavi from '../../../components/pageNavi.vue';

export default {
  components: {
    pageNavi,
  },
  props: {
    url: {
      type: String,
      default() {
        return '';
      },
    },
    perPage: {
      type: Number,
      default() {
        return 4;
      },
    },
  },
  data() {
    return {
      currentPage: 1,
      totalPages: 1,
      items: [],
    };
  },
  computed: {},
  watch: {
    $route: 'changeRoute',
  },
  mounted() {
    this.changeRoute();
  },
  methods: {
    /**
     * ページが変わった
     */
    changeRoute() {
      // ページ番号を取得。無ければ1
      if (this.$route.params.pageNumber) {
        this.currentPage = Number(this.$route.params.pageNumber);
        // REST API 取得
        this.getWpRest(this.currentPage);
      } else {
        // ルーターを変更した後で再度この changeRoute() が呼ばれ、その後 getWpRest() に行く
        this.$router.replace({ name: 'page', params: { pageNumber: 1 } });
      }
    },
    /**
     * Wordpress の jsonを取得
     */
    getWpRest() {
      const url = `${this.url}?per_page=${this.perPage}&page=${this.currentPage}`;

      // データを読み込む
      $.ajax(url, {
        cache: false,
        dataType: 'json',
      }).done((json, status, xhr) => {
        this.items = json;
        // 総ページ数
        this.totalPages = Number(xhr.getResponseHeader('x-wp-totalpages'));
      });
    },
  },
};
</script>
