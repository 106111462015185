/**
 * キャラのステート
 */

// import default_scheduleData from './default-scheduleData';

export default {
  scheduleData: [],
  // nowCharaId: '',
  // isNext: true, // 次のキャラ / 前のキャラ
};
