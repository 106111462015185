/**
 * wordpress 更新情報
 */

/* eslint no-console:off */

import Wp_posts_convert from '@sygnas/wp-posts-convert';
// カスタムスクロールバー
// import scrollbar from './home_scrollbar';

export default function() {
  const wp_posts = new Wp_posts_convert({
    type: 'rest',
    target: '.js-wp-posts',
    template: document.querySelector('.js-wp-posts-template').textContent,
  });

  wp_posts.start('/news/wp-json/wp/v2/posts?per_page=5').then(() => {
    // scrollbar();
  });
}
