/* eslint no-unused-vars:off */

// import 'babel-polyfill';

// 全ページ共通処理
import common from './pages/common/common';
// トップページ
import home from './pages/home/home';
// スケジュール
import schedule from './pages/schedule/index';
// Youtube一覧
import youtube from './pages/youtube/youtube';

common();
home();
schedule();
youtube();
