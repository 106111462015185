/**
 * メディアクエリーを管理
 */

import mediaquery from '@sygnas/mediaquery';

export default function() {
  mediaquery.set_media('pc', 'screen and (min-width: 801px)');
  mediaquery.set_media('sp', 'screen and (max-width: 800px)');
}
