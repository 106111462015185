<template>
  <div class="home-video-wrapper">
    <transition name="e-v-transition_fade">
      <div v-if="!isEnded" class="home-video" :data-loaded="isLoaded ? 'true' : 'false'" @click.prevent="finishVideo">
        <video
          class="home-video__video"
          :src="videoUrl"
          muted
          playsinline
          @canplay="canPlay"
          @ended="finishVideo"
        ></video>
        <span v-if="!isLoaded" class="p-loading home-video__loading"></span>
      </div>
    </transition>
  </div>
</template>

<script>
import Cookies from 'js-cookie';

const VIDEO_URL = 'https://harevutai.firebaseapp.com/harevutai_logo.mp4';

export default {
  data() {
    return {
      // フェードイン完了
      isFadeined: false,
      // 動画読み込み完了
      isLoaded: false,
      // 動画再生終了
      isEnded: false,
      // <video>
      video: null,
    };
  },
  computed: {
    videoUrl() {
      return this.isFadeined ? VIDEO_URL : '';
    },
  },
  watch: {
    isLoaded: 'checkAndPlayVideo',
    isFadeined: 'checkAndPlayVideo',
  },
  mounted() {
    this.video = this.$el.querySelector('video');

    // フェードインが終わったら動画を読み込む
    $(window).on('page_fadein', () => {
      this.isFadeined = true;
    });
  },
  methods: {
    /**
     * 再生可能状態になった
     */
    canPlay() {
      this.isLoaded = true;
    },
    /**
     * フェードイン完了、読み込み完了していた再生
     */
    checkAndPlayVideo() {
      if (this.isFadeined && this.isLoaded) {
        this.video.play();
        setCookie();
      }
    },
    /**
     * 動画を終了させる
     */
    finishVideo() {
      this.isEnded = true;
      document.querySelector('body').dataset.scrollLock = 'false';
      // ビデオ再生完了通知
      $(window).trigger('home_video_finished');
    },
  },
};

/**
 * 閲覧したことをCookieに保存
 * 24時間は表示しない
 */
function setCookie() {
  const now = new Date();
  now.setHours(now.getHours() + 24);
  Cookies.set('topVideo', 'true', { expires: now });
  // console.log('cookie', Cookies.get('topVideo'));
}
</script>
