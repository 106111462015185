var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass:
        "home-schedule-list l-grid l-grid--3 l-grid--1-sp l-grid--gap-x_3l"
    },
    _vm._l(_vm.schedules, function(schedule, index) {
      return _c("home-schedule-item", {
        key: "schedule-" + index,
        attrs: { schedule: schedule }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }