/**
 * 汎用 Vueタブ
 */
/*
  <syg-tabs
            :class-tab="c-tabmenu"
            :class-item="c-tabmenu__item"
            :class-link="c-tabmenu__link"
            :items="[
                    {name:'all', text:'ALL'},
                    {name:'type', params:{tab:'bd'}, text:'BD &amp; DVD'},
                    {name:'type', params:{tab:'music'}, text:'MUSIC'},
                    {name:'type', params:{tab:'others'}, text:'OTHERS'}
                    ]"
            :default="{nane:'type', params:{tab:'all'}}">
  </syg-tabs>

  <div v-if="$route.params.tab === 'bd'">
   〜〜
  </div>

  <div v-if="$route.params.tab === 'music'">
   〜〜
  </div>
 */

/* eslint no-new:off */

import Vue from 'vue';
import VueRouter from 'vue-router';
import VueLazyload from 'vue-lazyload';
import VueInview from '@sygnas/vue-inview';
// import VueTabs from '../../component/vue-tabs.vue';
import VueTabs from '@sygnas/vue-tabs';

export default function() {
  if ($('#app-tabs').length <= 0) return;

  // 遅延読み込み
  Vue.use(VueLazyload);
  // inview
  Vue.use(VueInview);

  // ルーター設定
  Vue.use(VueRouter);
  const router = new VueRouter({
    routes: [
      {
        path: '/',
      },
      {
        name: 'tab',
        path: '/:tab',
      },
    ],
  });

  new Vue({
    el: '#app-tabs',
    components: {
      VueTabs,
    },
    data: {
      message: 'hello',
    },
    router,
  });
}
